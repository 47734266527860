<script>
export default {
  name: "PageTitle",
  computed:{
    page(){ return this.$root.page }
  },
};
</script>

<template>
  <div class="row bg-title">
    <!-- .page title -->
    <div class="col-sm-6">
      <h4 class="page-title">
        <!-- <loading-spinner v-if="!page.PageName" light style="margin:0px"></loading-spinner> -->
        <span>{{page.PageName||"-"}}</span>
      </h4>
    </div>
    <div class="col-sm-6 text-right">
      <router-link v-if="page.Name" :to="{name:page.Name}" class="fcbtn btn btn-info btn-outline btn-1b tooltip-info" v-tooltip="'List '+page.ObjectName">
        <i class="fa fa-list"></i><span> List</span>
      </router-link>
      <router-link v-if="page.AddItem" :to="{name:page.Name,params:{id:'add'}}" class="fcbtn btn btn-success btn-outline btn-1b tooltip-success" v-tooltip="'Add '+page.ObjectName">
        <i class="fa fa-plus"></i><span> Add</span>
      </router-link>
    </div>
  </div>
</template>